import React from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'src/redux/store';
import { getDaysLeftInTrial } from 'src/utils/formatTime';
import { Subscription } from 'src/enums';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router';

export default function TrialLeft() {
  const navigate = useNavigate();
  const hide = useSelector((state) => state.user?.user?.subscription === Subscription.UNLIMITED);
  const daysLeft = useSelector((state) => getDaysLeftInTrial(state.user?.user?.trialStart));
  if (hide) return null;
  return (
    <Button
      sx={{ cursor: 'pointer' }}
      color="error"
      onClick={() => navigate(PATH_DASHBOARD.user.account + '?tab=billing')}
    >
      {daysLeft} days free trial left
    </Button>
  );
}
