import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

export interface ContentState {
  isLoading: boolean;
  error: null | any;
  content: any;
}

const initialState: ContentState = {
  isLoading: false,
  error: null,
  content: [],
};

const slice = createSlice({
  name: 'savedContent',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSavedContent(state, action) {
      state.isLoading = false;
      state.content = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function startLoading() {
  dispatch(slice.actions.startLoading());
}
export function setSavedContent(content: any) {
  // return async () => {
  //   // dispatch(slice.actions.startLoading());
  //   try {
  dispatch(slice.actions.setSavedContent(content));
  // } catch (error) {
  // dispatch(slice.actions.hasError(error));
  // }
  // };
}
