export enum SoMeContentType {
  FACEBOOK_POST = 'FACEBOOK_POST',
  LINKEDIN_POST = 'LINKEDIN_POST',
  INSTAGRAM_CAPTION = 'INSTAGRAM_CAPTION',
  TWEET = 'TWEET',
  TWITTER_THREAD = 'TWITTER_THREAD',
}

export enum ContentTypes {
  LINKEDIN = 'Linked-in',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  TWEET = 'Twitter Tweet',
  TWITTER_THREAD = 'Twitter Thread',
}

export enum AdsContentType {
  FACEBOOK_AD = 'FACEBOOK_AD',
  GOOGLE_AD = 'GOOGLE_AD',
}
export enum AdGoalType {
  AWARENESS = 'AWARENESS',
  TRAFFIC = 'TRAFFIC',
  CONVERTION = 'CONVERTION',
}

export enum AdTypes {
  FACEBOOK = 'Facebook ad',
  GOOGLE = 'Google ad',
}

export enum EmailType {
  NEWSLETTER = 'NEWSLETTER',
  SALES = 'SALES',
}

export enum EmailContentType {
  NEWSLETTER = 'Newsletter',
  SALES = 'Sales',
}

export enum ContentActionTypes {
  FACEBOOK_AD = 'FACEBOOK_AD',
  FACEBOOK_POST = 'FACEBOOK_POST',
  GOOGLE_AD = 'GOOGLE_AD',
  LINKEDIN_POST = 'LINKEDIN_POST',
  INSTAGRAM_CAPTION = 'INSTAGRAM_CAPTION',
  TWEET = 'TWEET',
  TWITTER_THREAD = 'TWITTER_THREAD',
  EMAIL = 'EMAIL',
  REPHRASE = 'REPHRASE',
  SHORTEN = 'SHORTEN',
  EXTEND = 'EXTEND',
}

export enum PerspectiveOptions {
  FIRST_PERSON = 'FIRST_PERSON',
  ACME = 'ACME',
  ALL = 'ALL',
}

export enum perspectiveTypes {
  FIRST_PERSON = 'First person',
  ACME = 'Acme',
  NONE = '',
}
export enum AdGoals {
  AWARENESS = 'Awareness',
  TRAFFIC = 'Traffic',
  CONVERTION = 'Convertion',
}

export const PERSPECTIVE_OPTIONS = [perspectiveTypes.FIRST_PERSON, perspectiveTypes.ACME];
export const AD_GOAL_OPTIONS = [
  { label: AdGoals.AWARENESS, value: AdGoalType.AWARENESS },
  { label: AdGoals.TRAFFIC, value: AdGoalType.TRAFFIC },
  { label: AdGoals.CONVERTION, value: AdGoalType.CONVERTION },
];
export const EMAIL_OPTIONS = [
  { label: EmailContentType.NEWSLETTER, value: EmailType.NEWSLETTER },
  { label: EmailContentType.SALES, value: EmailType.SALES },
];
export const CONTENT_OPTIONS = [
  ContentTypes.LINKEDIN,
  ContentTypes.INSTAGRAM,
  ContentTypes.FACEBOOK,
  ContentTypes.TWEET,
  ContentTypes.TWITTER_THREAD,
];
export const ADS_OPTIONS = [AdTypes.FACEBOOK, AdTypes.GOOGLE];
