import React from 'react';
import { RHFCheckbox, RHFSelect } from '../../components/hook-form';
import { useSelector } from '../../redux/store';
import { Box, Tooltip, Typography } from '@mui/material';
import Iconify from '../../components/Iconify';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../routes/paths';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const TONE_OF_VOICE = [
  { key: 'joyful', label: '😃 Joyful' },
  { key: 'excited', label: '🤩 Excited' },
  { key: 'formal', label: '👩‍💼 Formal' },
  { key: 'confident', label: '😎 Confident' },
];
const personalTovOption = { key: 'personalTov', label: '✍️ Your personal tone of voice' };

export default function TOVSelector() {
  const theme = useTheme();
  const hasPersonalTov = useSelector((state) => state.user.user?.personalTov);
  // const TOV = hasPersonalTov ? [personalTovOption, ...TONE_OF_VOICE] : TONE_OF_VOICE;
  if (!hasPersonalTov) return null;
  return (
    <Stack direction="row" alignItems="center">
      <RHFCheckbox name="tov" label="Writing DNA" />
      <Tooltip
        title={
          <Typography>
            Your writing DNA is based on your text{' '}
            <Link to={PATH_DASHBOARD.general.profileTov} style={{ color: theme.palette.info.main }}>
              here
            </Link>{' '}
            and is unique to you.
          </Typography>
        }
        placement="top"
      >
        <Box>
          <Iconify icon={'charm:info'} sx={{ color: 'text.secondary', mr: 2 }} />
        </Box>
      </Tooltip>
    </Stack>
  );
  // return (
  //   <RHFSelect name="tov" label="Tone of voice" size="small">
  //     {TOV.map(({ key, label }) => (
  //       <option key={key} value={key}>
  //         {label}
  //       </option>
  //     ))}
  //   </RHFSelect>
  // );
}
