import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useSelector } from '../../redux/store';
import { getIsProfileValid } from '../../pages/creator/utils';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import WritingProfileContainer from '../../pages/dashboard/WritingProfile';
import { DialogContent } from '@mui/material';
import WritingProfileTovContainer from '../../pages/dashboard/WritingProfileTov';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalInitialContainer() {
  const isLoading = useSelector((state) => state.user.isLoading);
  const id = useSelector((state) => state.user.user?.id);
  if (isLoading || !id) {
    return null;
  }
  return <FullScreenDialog />;
}
const steps = ['Your writing profile', 'Personalise your tone of voice'];

function FullScreenDialog() {
  const user = useSelector((state) => state.user.user);
  const profileSetup = getIsProfileValid(user);
  const hasPersonalisedTov = useSelector((state) => Boolean(state.user.user?.personalTov));
  // required values else open modal
  const isOpen = !profileSetup.status || !hasPersonalisedTov;
  const profileStatus = profileSetup.status;
  const [activeStep, setActiveStep] = React.useState(0);
  console.log(profileSetup);
  React.useEffect(() => {
    console.log('profileStatus', profileStatus);
    if (profileStatus && activeStep === 0) {
      setActiveStep(1);
    }
  }, [profileStatus]);

  const handleBack = () => {
    setActiveStep(0);
  };

  return (
    <Dialog fullScreen open={isOpen} TransitionComponent={Transition}>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3">
            Let's start by letting our AI get to know you, to give you the full Holy Cow experience
          </Typography>
          <Box mt={4} />
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {
            <React.Fragment>
              <Box mt={4} />
              {activeStep === 0 && <WritingProfileContainer />}
              {activeStep === 1 && <WritingProfileTovContainer hasBackBtn={false} />}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  // position: 'fixed',
                  // left: 94,
                  // bottom: 12,
                  pt: 2,
                }}
              >
                {/* <Button
                  size="large"
                  variant="outlined"
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button> */}
                <Box sx={{ flex: '1 1 auto' }} />

                {/* <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button> */}
              </Box>
            </React.Fragment>
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
}
