import { Grid, Stack, Typography, Box, Button } from '@mui/material';

import TextEditor from './TextEditor';
import { useSelector } from '../../redux/store';

export default function EditStep({ handleReset }: { handleReset: () => void }) {
  const content = useSelector((state) => state.content.content);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box sx={{ p: 4, pt: 6 }}>
          <Stack>
            <Typography variant="h3">
              {content?.length <= 0 ? 'All done' : 'Edit and save'}
            </Typography>
          </Stack>
          <Box sx={{ py: 2 }} />
          {content?.length <= 0 && (
            <Grid container spacing={6}>
              <Grid item xs={12} md={12}>
                <Stack>
                  <Typography variant="body1">
                    You've created some awesome content. You can now go make even more content!
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 3 }} onClick={handleReset}>
                    Go make more content
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
          {content?.length > 0 && (
            <Grid container spacing={6}>
              {content.map(({ id, label, icon, type, headline, description, config }: any) => (
                <Grid item xs={12} md={6} key={id}>
                  <TextEditor
                    id={id}
                    label={label}
                    icon={icon}
                    type={type}
                    headline={headline}
                    description={description}
                    config={config}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
