import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { DB } from '../contexts/FirebaseContext';
import { useSelector } from '../redux/store';

// if passed here it will load X users reviews
export default function useSuggestions() {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const { userId, perspective } = useSelector((state) => ({
    userId: state.user.user?.id,
    perspective: state.user.user?.writingFor,
  }));

  useEffect(() => {
    let unsubscribe: () => void;

    if (userId) {
      setIsLoading(true);
      const q = query(
        collection(DB, `users/${userId}/suggestions`),
        // where('perspective', '==', perspective),
        orderBy('createTime', 'desc'),
        limit(1)
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const content = querySnapshot.docs.map((doc) => doc.data().suggestions);

        setSuggestions(content[0]);
        setIsLoading(false);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId, perspective]);

  return { isLoading, suggestions };
}
