import React from 'react';
import Page from '../../components/Page';
import { Container, Grid, Stack, Typography, Box, Button, useTheme } from '@mui/material';
import { useSelector } from '../../redux/store';
import { Functions } from '../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { PerspectiveOptions } from '../creator/types';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { UserDoc } from '../../redux/slices/user';
import { LoadingButton } from '@mui/lab';
import LoadingScreen from '../../components/LoadingScreen';
import { httpsCallable } from 'firebase/functions';
import { PATH_DASHBOARD } from '../../routes/paths';
import Iconify from '../../components/Iconify';
import { useNavigate } from 'react-router-dom';

export default function WritingProfileTovContainer({ hasBackBtn = true }) {
  const isLoading = useSelector((state) => state.user.isLoading);

  if (isLoading) {
    return <LoadingScreen />;
  }
  return <WritingProfileTov hasBackBtn={hasBackBtn} />;
}
function WritingProfileTov({ hasBackBtn }: { hasBackBtn: boolean }) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user.user);
  const ProfileSchema = Yup.object().shape({
    personalText: Yup.string()
      .required('Text is required')
      .max(800, 'Text must be at most 800 characters'),
  });

  const defaultValues = {
    personalText: user?.personalText || '',
  };

  const methods = useForm<Partial<UserDoc>>({
    resolver: yupResolver(ProfileSchema),
    defaultValues,
  });
  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: { personalText: string }) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      if (!user?.id) return alert('something went wrong');
      try {
        const functionRef = httpsCallable(Functions, 'generateUserTOV');
        const response: any = await functionRef({
          prompt_tov: data.personalText,
        });
        // const configId = response?.data?.id;

        // @ts-ignore
        const error = response?.data?.httpErrorCode?.status;
        if (error) {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          return;
        }
        enqueueSnackbar('Your personal tone of voice was created', { variant: 'success' });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Profile failed to update', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const watchAllFields = watch();
  const navigate = useNavigate();

  return (
    <Page title="General: App">
      <Container maxWidth={'xl'}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {hasBackBtn && (
              <Grid item xs={12} md={12}>
                <Button
                  startIcon={<Iconify icon="ph:caret-left-bold" />}
                  onClick={() => navigate(PATH_DASHBOARD.general.writingProfile)}
                >
                  Back
                </Button>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Typography variant="h4" gutterBottom>
                Let's make your writing DNA
              </Typography>
              <Typography variant="body1" gutterBottom>
                Write or paste some text the represents how you write. Our AI will analyze it to
                personalize your experience on Holy Cow, so you always sound like ... you.
              </Typography>
            </Grid>
          </Grid>
          <Box mt={3} />
          <Grid container spacing={6}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                position: 'relative',
                opacity: watchAllFields?.writingFor === PerspectiveOptions.ACME ? 0.5 : 1,
              }}
            >
              <Box
                sx={
                  (watchAllFields?.personalText?.length || 0) >= 800
                    ? {
                        position: 'absolute',
                        right: 16,
                        bottom: Boolean(errors?.personalText) ? 34 : 8,
                        color: theme.palette.error.main,
                      }
                    : {
                        position: 'absolute',
                        right: 16,
                        bottom: Boolean(errors?.personalText) ? 34 : 8,
                      }
                }
              >
                {watchAllFields?.personalText?.length || 0} / 800
              </Box>
              <RHFTextField
                fullWidth
                name="personalText"
                label="Inspiration on what to paste: text from your website, your LinkedIn bio, a blog post you wrote, a social media post that went viral, whatever your wrote that you're proud of."
                // value={state.bioLong}
                // onChange={(e) => setState({ ...state, bioLong: e.target.value })}
                // onBlur={() => {
                //   handleUpdate({ bioLong: state.bioLong });
                // }}
                multiline
                minRows={5}
                sx={{
                  textarea: { paddingBottom: 2 },
                }}
              />
            </Grid>

            {/* <Grid item xs={12} md={4}>
            <TextField fullWidth label="Biography" multiline minRows={4} />
          </Grid> */}
          </Grid>
          <Stack sx={{ mt: 2 }}>
            <LoadingButton
              size="large"
              variant="contained"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </LoadingButton>
          </Stack>

          {/* <AppWelcome displayName={user?.displayName} /> */}
        </FormProvider>
      </Container>
    </Page>
  );
}
