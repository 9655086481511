// @mui
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Grid,
  Stack,
  Typography,
  Card,
  Box,
  Skeleton,
  CircularProgress,
} from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import Page from '../../components/Page';
import { useSelector } from '../../redux/store';
import useSavedContent from '../../hooks/useSavedContent';
import { ContentType as AdsContentType } from './AdsEditor';
import { ContentType as EmailContentType } from './EmailEditor';
import { ContentType as SocialMediaContentType } from './SocialMediaEditor';
import Iconify from '../../components/Iconify';
import Avatar from '../../components/Avatar';
import { CustomChip } from '../creator/SelectCategoryStep';
import { useState } from 'react';
import { ContentActionTypes } from '../creator/types';
import { formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

function getType(item: any) {
  switch (item.type) {
    case AdsContentType.FACEBOOK_AD:
      return { icon: 'brandico:facebook-rect', label: 'Facebook Ad' };
    case AdsContentType.GOOGLE_AD:
      return { icon: 'bi:google', label: 'Google Ad' };
    case EmailContentType.EMAIL:
      return { icon: 'ic:outline-email', label: 'Email' };
    case SocialMediaContentType.FACEBOOK_POST:
      return { icon: 'brandico:facebook-rect', label: 'Facebook Post' };
    case SocialMediaContentType.LINKEDIN_POST:
      return { icon: 'brandico:linkedin-rect', label: 'LinkedIn Post' };
    case SocialMediaContentType.INSTAGRAM_CAPTION:
      return { icon: 'akar-icons:instagram-fill', label: 'Instagram Caption' };

    default:
      return { icon: null, label: null };
  }
}

export default function GeneralContent() {
  const { user } = useAuth();
  const theme = useTheme();
  const [filters, setFilters] = useState<ContentActionTypes[]>([]);
  useSavedContent();
  const { loading, content } = useSelector((state) => ({
    loading: state.savedContent.isLoading,
    content: state.savedContent.content,
  }));

  const filteredContent =
    filters.length > 0 ? content.filter((c: any) => filters.includes(c.type)) : content;

  console.log({ content });

  const handleClick = (filter: ContentActionTypes) => {
    if (filters.includes(filter)) {
      return setFilters(filters.filter((f) => f !== filter));
    }
    return setFilters([...filters, filter]);
  };

  const renderContent = () => {
    if (loading) {
      return <CircularProgress />;
    }
    return filteredContent.map((item: any) => {
      const { icon, label } = getType(item);
      return (
        <Grid key={item.id} item xs={12} md={6}>
          <Stack
            sx={{ px: 3, pb: 1 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                <Iconify
                  icon={icon as string}
                  color={theme.palette.common.white}
                  width={20}
                  height={20}
                />
              </Avatar>

              <Typography variant="subtitle1">{label}</Typography>
            </Stack>
            <Stack>
              <Typography variant="body2" color="GrayText">
                Created {formatDistanceToNow(item.createTime.toDate())}
              </Typography>
            </Stack>
          </Stack>
          {item?.headline && (
            <Card
              sx={{
                p: 3,
                py: 1,
                backgroundColor: theme.palette.brand.light,
                position: 'relative',
                opacity: loading ? 0.5 : 1,
              }}
            >
              <Typography variant="body1">{item?.headline}</Typography>
            </Card>
          )}
          <Box sx={{ mt: 1 }} />
          <Card
            sx={{
              p: 3,
              backgroundColor: theme.palette.brand.light,
              position: 'relative',
              opacity: loading ? 0.5 : 1,
            }}
          >
            {loading && (
              <Box
                sx={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 100,
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <Stack sx={{ mb: 2 }}>
              <Typography variant="body1">{item?.description}</Typography>
            </Stack>
            {/* <Skeleton variant="rectangular" height={60} /> */}
          </Card>
        </Grid>
      );
    });
  };
  return (
    <Page title="General: App">
      <Container maxWidth={'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={3}>
              <Typography gutterBottom variant="h4">
                Filters
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <CustomChip
                variant={filters.includes(ContentActionTypes.FACEBOOK_POST) ? 'filled' : 'outlined'}
                label="Facebook posts"
                onClick={() => handleClick(ContentActionTypes.FACEBOOK_POST)}
              />
              <CustomChip
                variant={filters.includes(ContentActionTypes.LINKEDIN_POST) ? 'filled' : 'outlined'}
                label="Linkedin posts"
                onClick={() => handleClick(ContentActionTypes.LINKEDIN_POST)}
              />
              <CustomChip
                variant={
                  filters.includes(ContentActionTypes.INSTAGRAM_CAPTION) ? 'filled' : 'outlined'
                }
                label="Instagram"
                onClick={() => handleClick(ContentActionTypes.INSTAGRAM_CAPTION)}
              />
              <CustomChip
                variant={filters.includes(ContentActionTypes.FACEBOOK_AD) ? 'filled' : 'outlined'}
                label="Facebook Ad"
                onClick={() => handleClick(ContentActionTypes.FACEBOOK_AD)}
              />
              <CustomChip
                variant={filters.includes(ContentActionTypes.GOOGLE_AD) ? 'filled' : 'outlined'}
                label="Google Ad"
                onClick={() => handleClick(ContentActionTypes.GOOGLE_AD)}
              />
            </Stack>
            <Box sx={{ mb: 2 }} />
            {/* <AppWelcome displayName={user?.displayName} /> */}
          </Grid>
          {renderContent()}
        </Grid>
      </Container>
    </Page>
  );
}
