// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Container, Grid, Stack, Typography, Box, Chip } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import { useEffect, useState } from 'react';
import HorizontalNonLinearStepper from './Stepper';
import TopicStepSome from './TopicStepSome';
import SelectCategoryStep from './SelectCategoryStep';
import EditStep from './EditStep';
import JustWrite from './JustWrite';
import TopicStepAds from './TopicStepAds';
import TopicStepEmail from './TopicStepEmail';
import { useSelector } from 'src/redux/store';

export enum Categories {
  SOME = 'SOME',
  ADS = 'ADS',
  EMAIL = 'EMAIL',
  BLOG = 'BLOG',
  JUST_WRITE = 'JUST_WRITE',
}

// ----------------------------------------------------------------------

export default function CreatorPage() {
  const { user } = useAuth();
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const [data, setData] = useState<any[]>([]);
  const [category, setCategory] = useState<Categories | null>(null);
  console.log({ category });

  return (
    <Page title="Creator: App">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={3}>
              <HorizontalNonLinearStepper
                render={(activeStep, handleNext, handleStepReset) => {
                  const handleClick = (category: Categories) => {
                    setCategory(category);
                    handleNext();
                  };

                  const handleNextStep = () => {
                    handleNext();
                  };

                  const handleResetCreator = () => {
                    handleStepReset();
                    setCategory(null);
                  };
                  if (activeStep === 0) {
                    return <SelectCategoryStep handleClick={handleClick} />;
                  }
                  if (category === Categories.JUST_WRITE) {
                    return <JustWrite />;
                  }
                  if (activeStep === 1 && category === Categories.SOME) {
                    return <TopicStepSome handleNextStep={handleNextStep} />;
                  }
                  if (activeStep === 1 && category === Categories.ADS) {
                    return <TopicStepAds handleNextStep={handleNextStep} />;
                  }
                  if (activeStep === 1 && category === Categories.EMAIL) {
                    return <TopicStepEmail handleNextStep={handleNextStep} />;
                  }
                  if (activeStep === 2) {
                    return <EditStep handleReset={handleResetCreator} />;
                  }
                  return <div />;
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
