import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import {
  Container,
  Grid,
  Stack,
  Typography,
  Box,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from '@mui/material';
import Lottie, { circleYellow } from '../../components/lottie/index';
import { useSelector } from '../../redux/store';
import { doc, collection, setDoc } from 'firebase/firestore';
import { DB } from '../../contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { PerspectiveOptions } from '../creator/types';
import { FormProvider, RHFTextField, RHFRadioGroup } from '../../components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { UserDoc } from '../../redux/slices/user';
import { LoadingButton } from '@mui/lab';
import LoadingScreen from '../../components/LoadingScreen';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../routes/paths';

export default function WritingProfileContainer() {
  const isLoading = useSelector((state) => state.user.isLoading);

  if (isLoading) {
    return <LoadingScreen />;
  }
  return <WritingProfile />;
}
function WritingProfile() {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user.user);
  const ProfileSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    companyName: Yup.string()
      .when('writingFor', {
        is: PerspectiveOptions.ACME,
        then: Yup.string().required('Company name is required'),
      })
      .when('writingFor', {
        is: PerspectiveOptions.ALL,
        then: Yup.string().required('Company name is required'),
      }),
    companyIndustry: Yup.string()
      .when('writingFor', {
        is: PerspectiveOptions.ACME,
        then: Yup.string().required('Company industry is required'),
      })
      .when('writingFor', {
        is: PerspectiveOptions.ALL,
        then: Yup.string().required('Company industry is required'),
      }),
    companyDescription: Yup.string()
      .when('writingFor', {
        is: PerspectiveOptions.ACME,
        then: Yup.string().required('Company description is required'),
      })
      .when('writingFor', {
        is: PerspectiveOptions.ALL,
        then: Yup.string().required('Company description is required'),
      }),
    writingFor: Yup.string().required('Who you are writing for is required'),
    personalIndustry: Yup.string()
      .when('writingFor', {
        is: PerspectiveOptions.FIRST_PERSON,
        then: Yup.string().required('Industry is required'),
      })
      .when('writingFor', {
        is: PerspectiveOptions.ALL,
        then: Yup.string().required('Industry is required'),
      }),
    bioShort: Yup.string()
      .when('writingFor', {
        is: PerspectiveOptions.FIRST_PERSON,
        then: Yup.string().required('Your introduction is required'),
      })
      .when('writingFor', {
        is: PerspectiveOptions.ALL,
        then: Yup.string().required('Your introduction is required'),
      }),
    bioLong: Yup.string()
      .when('writingFor', {
        is: PerspectiveOptions.FIRST_PERSON,
        then: Yup.string().required('Description is required'),
      })
      .when('writingFor', {
        is: PerspectiveOptions.ALL,
        then: Yup.string().required('Description is required'),
      }),
  });
  const [firstName, lastName] = user?.displayName?.split(' ') || [];

  const defaultValues = {
    firstname: user?.firstname || firstName || '',
    lastname: user?.lastname || lastName || '',
    companyName: user?.companyName || '',
    companyIndustry: user?.companyIndustry || '',
    companyDescription: user?.companyDescription || '',
    writingFor: (user?.writingFor || '') as PerspectiveOptions,
    personalIndustry: user?.personalIndustry || '',
    bioShort: user?.bioShort || '',
    bioLong: user?.bioLong || '',
  };

  const methods = useForm<Partial<UserDoc>>({
    resolver: yupResolver(ProfileSchema),
    defaultValues,
  });
  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const navigate = useNavigate();

  const handleUpdate = async (data: any) => {};

  const onSubmit = async (data: Partial<UserDoc>) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      if (!user?.id) return alert('something went wrong');
      try {
        const userRef = doc(collection(DB, 'users'), user.id);

        await setDoc(userRef, data, { merge: true });
        enqueueSnackbar('Profile updated', { variant: 'success' });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Profile failed to update', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const watchAllFields = watch();

  return (
    <Page title="General: App">
      <Container maxWidth={'xl'}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Stack
                sx={{ position: 'relative' }}
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Box
                  sx={{
                    height: 300,
                    display: 'flex',
                    justifyContent: 'center',
                    width: 400,
                    cursor: 'pointer',
                  }}
                  role="button"
                  onClick={() => navigate(PATH_DASHBOARD.general.profileTov)}
                >
                  <Lottie animation={circleYellow} />
                  <Box
                    sx={{
                      position: 'absolute',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      display: 'flex',
                    }}
                  >
                    <Stack alignItems="center">
                      <Typography gutterBottom variant="caption" fontSize={32}>
                        Writing DNA
                      </Typography>
                      <Typography gutterBottom variant="body1">
                        Edit
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <RHFTextField
                fullWidth
                name="firstname"
                label="Firstname"
                // value={state.firstname}
                // onChange={(e) => setState({ ...state, firstname: e.target.value })}
                // onBlur={() => {
                //   handleUpdate({ firstname: state.firstname });
                // }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RHFTextField
                fullWidth
                name="lastname"
                label="Lastname"
                // value={state.lastname}
                // onChange={(e) => setState({ ...state, lastname: e.target.value })}
                // onBlur={() => {
                //   handleUpdate({ lastname: state.lastname });
                // }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">I am writing for</FormLabel>
                <RHFRadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="writingFor"
                  options={[
                    PerspectiveOptions.FIRST_PERSON,
                    PerspectiveOptions.ACME,
                    PerspectiveOptions.ALL,
                  ]}
                  getOptionLabel={['Myself (eg. freelancer, influencer)', 'A company', 'Both']}
                />
                {/* <FormControlLabel
                    value={PerspectiveOptions.FIRST_PERSON}
                    control={<Radio />}
                    label="Myself (eg. freelancer, influencer)"
                  />
                  <FormControlLabel
                    value={PerspectiveOptions.ACME}
                    control={<Radio />}
                    label="A company"
                  />
                  <FormControlLabel
                    value={PerspectiveOptions.ALL}
                    control={<Radio />}
                    label="Both"
                  />
                </RadioGroup> */}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ opacity: watchAllFields?.writingFor === PerspectiveOptions.ACME ? 0.5 : 1 }}
            >
              <Typography variant="h2">You</Typography>
              <Box sx={{ mt: 2 }} />
              <RHFTextField
                fullWidth
                name="bioShort"
                label="How do you present yourself in one or two words"
                // value={state.bioShort}
                // onChange={(e) => setState({ ...state, bioShort: e.target.value })}
                // onBlur={() => {
                //   handleUpdate({ bioShort: state.bioShort });
                // }}
              />
              <Box sx={{ mt: 4 }} />
              <RHFTextField
                fullWidth
                label="Industry/Area"
                name="personalIndustry"
                // value={state.personalIndustry}
                // onChange={(e) => setState({ ...state, personalIndustry: e.target.value })}
                // onBlur={() => {
                //   handleUpdate({ personalIndustry: state.personalIndustry });
                // }}
              />
              <Box sx={{ mt: 4 }} />
              <RHFTextField
                fullWidth
                name="bioLong"
                label="Describe in few words what is you are doing"
                // value={state.bioLong}
                // onChange={(e) => setState({ ...state, bioLong: e.target.value })}
                // onBlur={() => {
                //   handleUpdate({ bioLong: state.bioLong });
                // }}
                multiline
                minRows={5}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                opacity: watchAllFields?.writingFor === PerspectiveOptions.FIRST_PERSON ? 0.5 : 1,
              }}
            >
              <Typography variant="h2">Company information</Typography>
              <Box sx={{ mt: 2 }} />
              <RHFTextField
                fullWidth
                name="companyName"
                label="Company name"
                // value={state.companyName}
                // onChange={(e) => setState({ ...state, companyName: e.target.value })}
                // onBlur={() => {
                //   handleUpdate({ companyName: state.companyName });
                // }}
              />
              <Box sx={{ mt: 4 }} />
              <RHFTextField
                fullWidth
                name="companyIndustry"
                label="Industry"
                // value={state.companyIndustry}
                // onChange={(e) => setState({ ...state, companyIndustry: e.target.value })}
                // onBlur={() => {
                //   handleUpdate({ companyIndustry: state.companyIndustry });
                // }}
              />
              <Box sx={{ mt: 4 }} />
              <RHFTextField
                fullWidth
                name="companyDescription"
                label="Describe in few words what is your company doing"
                // value={state.companyDescription}
                // onChange={(e) => setState({ ...state, companyDescription: e.target.value })}
                // onBlur={() => {
                //   handleUpdate({ companyDescription: state.companyDescription });
                // }}
                multiline
                minRows={5}
              />
            </Grid>
            {/* <Grid item xs={12} md={4}>
            <TextField fullWidth label="Biography" multiline minRows={4} />
          </Grid> */}
          </Grid>
          <Box mt={6} />
          <Stack>
            <LoadingButton
              size="large"
              variant="contained"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </LoadingButton>
          </Stack>

          {/* <AppWelcome displayName={user?.displayName} /> */}
        </FormProvider>
      </Container>
    </Page>
  );
}
