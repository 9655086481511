import React, { useState, Dispatch } from 'react';
import {
  FormProvider,
  RHFCheckbox,
  RHFEditor,
  RHFRadioGroup,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
} from '../../components/hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import {
  Grid,
  Stack,
  Card,
  TextField,
  InputAdornment,
  Autocomplete,
  Chip,
  Tooltip,
  IconButton,
  Typography,
  Skeleton,
  Button,
} from '@mui/material';
import { LabelStyle } from '../../sections/@dashboard/e-commerce/ProductNewForm';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/Iconify';

// import InfoIcon from '@mui/icons-material/Info';
import { Box, useTheme } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckInIllustration from '../../assets/illustration_checkin';
import { AUTH } from '../../contexts/FirebaseContext';
import TOVSelector from './TOVSelector';
import { setContent } from '../../redux/slices/content';
import { useSelector } from '../../redux/store';

const CATEGORY_OPTION = [
  { group: 'Clothing', classify: ['Shirts', 'T-shirts', 'Jeans', 'Leather'] },
  { group: 'Tailored', classify: ['Suits', 'Blazers', 'Trousers', 'Waistcoats'] },
  { group: 'Accessories', classify: ['Shoes', 'Backpacks and bags', 'Bracelets', 'Face masks'] },
];

const TAGS_OPTION = [
  'Toy Story 3',
  'Logan',
  'Full Metal Jacket',
  'Dangal',
  'The Sting',
  '2001: A Space Odyssey',
  "Singin' in the Rain",
  'Toy Story',
  'Bicycle Thieves',
  'The Kid',
  'Inglourious Basterds',
  'Snatch',
  '3 Idiots',
];

interface Blog {
  description: string;
  inStock: any;
  code: any;
  sku: any;
  category: any;
  tags: any;
  price: any;
  priceSale: any;
  taxes: any;
  facebookPost: any;
  linkedInPost: any;
  igCaption: any;
  tov: string;
}

export enum ContentType {
  FACEBOOK_POST = 'FACEBOOK_POST',
  LINKEDIN_POST = 'LINKEDIN_POST',
  INSTAGRAM_CAPTION = 'INSTAGRAM_CAPTION',
  TWEET = 'TWEET',
  TWITTER_THREAD = 'TWITTER_THREAD',
}

export function getPostType(content: any) {
  const keys = Object.keys(content);

  if (keys.includes(ContentType.FACEBOOK_POST)) {
    return {
      ...content[ContentType.FACEBOOK_POST],
      type: ContentType.FACEBOOK_POST,
      icon: 'brandico:facebook-rect',
      label: 'Facebook Post',
    };
  }

  if (keys.includes(ContentType.LINKEDIN_POST)) {
    return {
      ...content[ContentType.LINKEDIN_POST],
      type: ContentType.LINKEDIN_POST,
      icon: 'brandico:linkedin-rect',
      label: 'LinkedIn Post',
    };
  }
  if (keys.includes(ContentType.INSTAGRAM_CAPTION)) {
    return {
      ...content[ContentType.INSTAGRAM_CAPTION],
      type: ContentType.INSTAGRAM_CAPTION,
      icon: 'akar-icons:instagram-fill',
      label: 'Instagram Caption',
    };
  }
  if (keys.includes(ContentType.TWITTER_THREAD)) {
    return {
      ...content[ContentType.TWITTER_THREAD],
      type: ContentType.TWITTER_THREAD,
      icon: 'akar-icons:twitter-fill',
      label: 'Twitter thread',
    };
  }
  if (keys.includes(ContentType.TWEET)) {
    return {
      ...content[ContentType.TWEET],
      type: ContentType.TWEET,
      icon: 'akar-icons:twitter-fill',
      label: 'Tweet',
    };
  }
  console.warn('No content type found');

  return null;
}

async function post(endpoint: string, body: any): Promise<any[]> {
  try {
    const token = (await AUTH.currentUser?.getIdToken()) ?? null;
    const response = await fetch(endpoint, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    console.log(response);

    const data = await response.json();
    return data as any[];
  } catch (error) {
    console.error(error);
    return [];
  }
}

export default function SocialMediaEditor({ setData }: { setData: Dispatch<any[]> }) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const hasLockedFacebookPost = useSelector((state) =>
    state.content.content.find((c: any) => c.type === ContentType.FACEBOOK_POST && c.locked)
  );
  const hasLockedLinkedinPost = useSelector((state) =>
    state.content.content.find((c: any) => c.type === ContentType.LINKEDIN_POST && c.locked)
  );
  const hasLockedInstagramPost = useSelector((state) =>
    state.content.content.find((c: any) => c.type === ContentType.INSTAGRAM_CAPTION && c.locked)
  );

  const NewBlogSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    tov: Yup.string(), //.required('Description is required'),
    facebookPost: Yup.bool(),
    linkedInPost: Yup.bool(),
    igCaption: Yup.bool(),
  });

  const defaultValues = {
    description: '',
    content: '',
    cover: null,
    tags: ['Logan'],
    publish: true,
    comments: true,
    metaTitle: '',
    metaDescription: '',
    metaKeywords: ['Logan'],
    tov: 'joyful',
  };

  const methods = useForm<Blog>({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const contents = [
    // {
    //   type: ContentType.FACEBOOK,
    //   text: 'Your super dope AI generated ads text to share on facebook',
    // },
    // {
    //   type: ContentType.GOOGLE,
    //   text: 'Your super dope AI generated ads text to share on Google',
    // },
  ] as any[];

  const onSubmit = async (data: Blog) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      let types: any[] = [];
      console.log({ data });

      if (data.facebookPost && !hasLockedFacebookPost) {
        types.push(ContentType.FACEBOOK_POST);
      }
      if (data.linkedInPost && !hasLockedLinkedinPost) {
        types.push(ContentType.LINKEDIN_POST);
      }
      if (data.igCaption && !hasLockedInstagramPost) {
        types.push(ContentType.INSTAGRAM_CAPTION);
      }

      if (types.length < 1) {
        enqueueSnackbar('Please select at least one content type', { variant: 'error' });
        return;
      }

      console.log({ types, d: data.description });

      const response = await post(
        'https://europe-west1-the-professor-app.cloudfunctions.net/prompt',
        {
          types,
          prompt: data.description,
          tov: data.tov,
        }
      );

      setContent(
        response
          .map(getPostType)
          .map((c: any) => ({ ...c, config: { prompt: data.description, tov: data.tov, types } }))
      );

      // reset();
      enqueueSnackbar('Post success!');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid item xs={12} md={6}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ p: 3 /*height: 'calc(100vh - 160px)' */ }}>
          <Stack spacing={3} mt={2}>
            <Stack>
              <LabelStyle>What should we create today?</LabelStyle>
              <Stack direction="row">
                <RHFCheckbox name="facebookPost" label="Facebook post" />
                <Tooltip title="Create Facebook post?" placement="top">
                  <Box>
                    <Iconify icon={'charm:info'} sx={{ color: 'text.secondary', mr: 2 }} />
                  </Box>
                </Tooltip>
                <RHFCheckbox name="linkedInPost" label="LinkedIn post" />
                <Tooltip title="Create a LinkedIn post?" placement="top">
                  <Box>
                    <Iconify icon={'charm:info'} sx={{ color: 'text.secondary', mr: 2 }} />
                  </Box>
                </Tooltip>
              </Stack>
              <Stack direction="row">
                <RHFCheckbox name="igCaption" label="Instagram caption" />
                <Tooltip title="Create Instagram caption?" placement="top">
                  <Box>
                    <Iconify icon={'charm:info'} sx={{ color: 'text.secondary', mr: 2 }} />
                  </Box>
                </Tooltip>
                {/* <RHFCheckbox name="linkedInPost" label="LinkedIn post" />
                <Tooltip title="Create a LinkedIn post?" placement="top">
                  <Box>
                    <Iconify icon={'charm:info'} sx={{ color: 'text.secondary', mr: 2 }} />
                  </Box>
                </Tooltip> */}
              </Stack>
              {/* <RHFCheckbox name="bullets" label="Bullets" />
                  <Tooltip title="Create some dope bullets?" placement="top">
                    <Box>
                      <Iconify icon={'charm:info'} sx={{ color: 'text.secondary', mr: 2 }} />
                    </Box>
                  </Tooltip> */}
            </Stack>
            {/* <Stack>
                <LabelStyle>Write a dope headline</LabelStyle>
                <RHFTextField label="Write a dope headline" name="title" />
              </Stack> */}
            <Stack>
              <LabelStyle>
                Write a great description of the topic you want to create content for
              </LabelStyle>
              <RHFTextField
                label="Write a great description of the topic you want to create content for"
                multiline
                minRows={3}
                name="description"
              />
            </Stack>
            <Stack>
              <TOVSelector />
            </Stack>
            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {'Create content! 🦾🤖'}
            </LoadingButton>
          </Stack>
          {/* <RHFEditor simple name="description" style={{ height: 'calc(100vh - 260px)' }} /> */}
        </Card>
      </FormProvider>
    </Grid>
  );
}
