export enum Subscription {
  UNLIMITED = 'unlimited',
}

export enum Prices {
  STARTER = 'price_1MgVi8GebAMkEUoa6IZqL3GY',
  UNLIMITED = 'price_1NCq4OGebAMkEUoa3hYZe6a1',
}

export enum CreditDefault {
  BASE_CREDITS = 150,
  UNLIMITED_FAIR_UAGE = 15000,
}
