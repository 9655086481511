import { useEffect } from 'react';
import { collection, query, orderBy, limit, onSnapshot, where } from 'firebase/firestore';
import { DB } from '../contexts/FirebaseContext';
import { setSavedContent, startLoading } from '../redux/slices/savedContent';
import useAuth from './useAuth';

// if passed here it will load X users reviews
export default function useSavedContent(uid?: string | null) {
  const { user } = useAuth();

  const userId = user?.id;
  useEffect(() => {
    let unsubscribe: () => void;
    console.log({ userId });

    if (userId) {
      startLoading();
      const q = query(
        collection(DB, `users/${userId}/content`),
        orderBy('createTime', 'desc'),
        limit(100)
      );
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const content = querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as any)
        );

        setSavedContent(content);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);

  return;
}
