import { UserDoc } from '../../redux/slices/user';
import { PerspectiveOptions, perspectiveTypes } from './types';

export function getPerspective({
  perspective,
  writingFor,
}: {
  perspective?: perspectiveTypes;
  writingFor: PerspectiveOptions;
}) {
  if (perspectiveTypes.FIRST_PERSON === perspective) {
    return PerspectiveOptions.FIRST_PERSON;
  }
  if (perspectiveTypes.ACME === perspective) {
    return PerspectiveOptions.ACME;
  }
  return writingFor;
}
/**
 * description: check if user profile is valid for perspective value
 * @param user
 * @returns
 */
export function getIsProfileValid(user: UserDoc | null) {
  if (!user) return { status: false, message: 'Please complete your profile' };
  if (user?.writingFor === PerspectiveOptions.FIRST_PERSON) {
    if (!user?.bioShort || !user?.bioLong || !user?.personalIndustry) {
      return { status: false, message: 'Please complete your profile' };
    }
  }
  if (user?.writingFor === PerspectiveOptions.ACME) {
    if (!user?.companyDescription || !user?.companyIndustry || !user?.companyName) {
      return { status: false, message: 'Please complete your profile' };
    }
  }
  if (user?.writingFor === PerspectiveOptions.ALL) {
    if (
      !user?.companyDescription ||
      !user?.companyIndustry ||
      !user?.companyName ||
      !user?.bioShort ||
      !user?.bioLong ||
      !user?.personalIndustry
    ) {
      return { status: false, message: 'Please complete your profile' };
    }
  }
  if (!user?.writingFor) {
    return {
      status: false,
      message: 'Please choose who you are writing for (in the writing profile)',
    };
  }
  console.log({ user });
  return { status: true };
}
