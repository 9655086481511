import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

export interface ContentHistoryState {
  isLoading: boolean;
  error: null | any;
  setContentHistory: { [id: string]: any[] };
}

const initialState: ContentHistoryState = {
  isLoading: false,
  error: null,
  setContentHistory: {
    d9brQd6a8VD88QGZaIVX: [
      /*{ id: 1, description: 'hh hhhafh ha hah h afhhsahhh ' }*/
      {
        id: 'd9brQd6a8VD88QGZaIVX',
        headline: 'Learn AI and Be Future-Ready! 🤖',
        description:
          'Learn to create AI solutions with our new course! Join our community of AI experts and let the power of intelligence unlock your potential..',
        type: 'FACEBOOK_AD',
        icon: 'brandico:facebook-rect',
        label: 'Facebook Ad',
        config: {
          types: ['FACEBOOK_AD'],
          prompt: 'My new AI course',
          perspective: 'Acme',
          adGoal: 'AWARENESS',
          tov: 'joyful',
        },
      },
    ],
  },
};

const slice = createSlice({
  name: 'setContentHistory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setContentHistory(state, action) {
      state.isLoading = false;
      state.setContentHistory = {
        ...state.setContentHistory,
        [action.payload.contentId]: [
          ...state.setContentHistory[action.payload.contentId],
          action.payload.content,
        ],
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function setContentHistory(contentId: string, content: any) {
  // return async () => {
  //   // dispatch(slice.actions.startLoading());
  //   try {
  dispatch(slice.actions.setContentHistory({ contentId, content }));
  // } catch (error) {
  // dispatch(slice.actions.hasError(error));
  // }
  // };
}
