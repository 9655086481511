// @mui

import {
  Grid,
  Stack,
  Typography,
  Box,
  FormHelperText,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';

// import SocialMediaEditor from './SocialMediaEditor';
import Iconify from '../../components/Iconify';

import TOVSelector from '../dashboard/TOVSelector';
import { FormProvider, RHFMultiCheckbox, RHFTextField } from '../../components/hook-form';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { post } from './api';
import * as Yup from 'yup';
import {
  ContentTypes,
  CONTENT_OPTIONS,
  PERSPECTIVE_OPTIONS,
  SoMeContentType,
  PerspectiveOptions,
  perspectiveTypes,
} from './types';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { setContent } from '../../redux/slices/content';
import { getPostType } from '../dashboard/SocialMediaEditor';
import { LoadingButton } from '@mui/lab';
import Suggestions from './Suggestions';
import { useSelector } from '../../redux/store';
import { getIsProfileValid, getPerspective } from './utils';

interface Blog {
  description: string;
  tov: boolean;
  perspective?: perspectiveTypes;
  contentOptions: string[];
}
export default function TopicStepSome({ handleNextStep }: { handleNextStep: VoidFunction }) {
  const confirm = useConfirm();
  const user = useSelector((state) => state.user.user);
  const { enqueueSnackbar } = useSnackbar();
  const NewBlogSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    tov: Yup.boolean(), //.required('Description is required'),
    perspective: Yup.string(),
    contentOptions: Yup.array().min(1, 'Select at least one option'),
  });

  const profileSetup = getIsProfileValid(user);

  const defaultValues = {
    description: '',
    tov: user?.personalTov ? true : false,
    perspective: undefined,
    contentOptions: [],
  };

  const methods = useForm<Blog>({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data: Blog) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      let types: any[] = [];

      if (data.contentOptions.includes(ContentTypes.FACEBOOK)) {
        types.push(SoMeContentType.FACEBOOK_POST);
      }
      if (data.contentOptions.includes(ContentTypes.LINKEDIN)) {
        types.push(SoMeContentType.LINKEDIN_POST);
      }
      if (data.contentOptions.includes(ContentTypes.INSTAGRAM)) {
        types.push(SoMeContentType.INSTAGRAM_CAPTION);
      }
      if (data.contentOptions.includes(ContentTypes.TWEET)) {
        types.push(SoMeContentType.TWEET);
      }
      if (data.contentOptions.includes(ContentTypes.TWITTER_THREAD)) {
        types.push(SoMeContentType.TWITTER_THREAD);
      }

      if (types.length < 1) {
        enqueueSnackbar('Please select at least one content type', { variant: 'error' });
        return;
      }

      if (!user?.writingFor) {
        enqueueSnackbar('Missing who you are writing for', { variant: 'error' });
        return;
      }

      const perspective = getPerspective({
        perspective: data.perspective,
        writingFor: user?.writingFor,
      });

      const config = {
        types,
        prompt: data.description,
        perspective: perspective,
        tov: data.tov ? 'personalTov' : '',
      };

      const response = await post(
        'https://europe-west1-the-professor-app.cloudfunctions.net/prompt',
        config
      );
      if (response[0].status === 429) {
        return enqueueSnackbar('Too many requests, please try again later', { variant: 'error' });
      }
      if (response[0].status === 402) {
        return enqueueSnackbar('Sorry you are out of credits', { variant: 'error' });
      }
      if (response[0].status === 403) {
        return enqueueSnackbar('Sorry you are not authorized for this', { variant: 'error' });
      }
      if (response[0].status >= 400) {
        return enqueueSnackbar('Something went wrong, please try again later', {
          variant: 'error',
        });
      }

      setContent(
        response.map(getPostType).map((c: any) => ({
          ...c,
          config,
        }))
      );
      handleNextStep();

      // reset();
      enqueueSnackbar('Post success!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddSuggetion = (text: string) => {
    if (values.description?.trim()) {
      confirm({ title: 'This will overwrite your current description' }).then(() =>
        setValue('description', text)
      );
      // .catch(() => {})
    } else {
      setValue('description', text);
    }
  };

  console.log({ user });
  const [linkedin, instagram, facebook, tweet, twitterThread] = CONTENT_OPTIONS;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box sx={{ p: 4, pt: 6 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <Typography variant="h3">What do you want to write about?</Typography>
                <Box sx={{ py: 2 }} />
                <RHFTextField
                  fullWidth
                  multiline
                  minRows={5}
                  name="description"
                  placeholder="Write a great description of the topic you want to create content for"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Suggestions handleAddSuggetion={handleAddSuggetion} />
              </Grid>
              <Box sx={{ py: 2 }} />
              <Grid item xs={3} md={3}>
                <Stack sx={{ pb: 2 }}>
                  <Typography variant="subtitle1">Tone of voice</Typography>
                </Stack>
                <Stack sx={{ pb: 2 }}>
                  <TOVSelector />
                </Stack>
                {PerspectiveOptions.ALL === user?.writingFor && (
                  <>
                    <Controller
                      name="perspective"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          {PERSPECTIVE_OPTIONS.map((item, index) => {
                            const isCompany = item === perspectiveTypes.ACME;
                            return (
                              <FormControlLabel
                                key={item}
                                value={item}
                                control={<Radio disableRipple color="default" />}
                                label={isCompany ? user.companyName || item : item}
                                sx={{
                                  my: 0.5,
                                  borderRadius: 1,
                                  // '&:hover': { opacity: 0.48 },
                                  // ...(field.value.includes(item) && {
                                  //   bgcolor: 'action.selected',
                                  // }),
                                }}
                              />
                            );
                          })}
                        </RadioGroup>
                      )}
                    />
                    <FormHelperText error sx={{ textTransform: 'capitalize' }}>
                      {
                        // @ts-ignore
                        errors?.perspective?.message
                      }
                    </FormHelperText>
                  </>
                )}
              </Grid>

              <Grid item xs={3} md={3}>
                <Stack direction="row" justifyContent="center">
                  <Stack>
                    <Box sx={{ pb: 2 }}>
                      <Typography variant="subtitle1">Create for</Typography>
                    </Box>

                    <RHFMultiCheckbox
                      name="contentOptions"
                      options={[linkedin, instagram, facebook]}
                    />
                    <FormHelperText error sx={{ textTransform: 'capitalize' }}>
                      {
                        // @ts-ignore
                        errors?.contentOptions?.message
                      }
                    </FormHelperText>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={3} md={3}>
                <Stack direction="row" justifyContent="center">
                  <Stack>
                    <Box sx={{ pb: 5 }} />

                    <RHFMultiCheckbox name="contentOptions" options={[tweet, twitterThread]} />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  variant="contained"
                  size="large"
                  disabled={!profileSetup.status}
                  loading={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                >
                  Create
                </LoadingButton>
                {!profileSetup?.status && (
                  <Typography variant="body2" color="error">
                    {profileSetup?.message}
                  </Typography>
                )}
                {/* <Tooltip
                  placement="bottom-start"
                  title={`The reason you will use ${values.contentOptions.length} credits is because each piece of content costs one credit to make`}
                >
                  <Stack sx={{ pt: 2 }} direction="row" alignItems="center" spacing={1}>
                    <Iconify icon="material-symbols:info-outline" />
                    <Typography variant="body2">
                      You will use {values.contentOptions.length} credits
                    </Typography>
                  </Stack>
                </Tooltip> */}
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
      </Grid>
    </Grid>
  );
}
