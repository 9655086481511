import { useLottie } from 'lottie-react';
import circleYellow from './circle-yellow.json';

const Lottie = ({ animation }: any) => {
  const options = {
    animationData: animation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return View;
};

export default Lottie;

export { circleYellow };
