import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

export interface ContentState {
  isLoading: boolean;
  error: null | any;
  content: any[];
}

const initialState: ContentState = {
  isLoading: false,
  error: null,
  content: [
    /*{ id: 1, description: 'hh hhhafh ha hah h afhhsahhh ' }*/
    {
      id: 'd9brQd6a8VD88QGZaIVX',
      headline: 'Learn AI and Be Future-Ready! 🤖',
      description:
        'Learn to create AI solutions with our new course! Join our community of AI experts and let the power of intelligence unlock your potential..',
      type: 'FACEBOOK_AD',
      icon: 'brandico:facebook-rect',
      label: 'Facebook Ad',
      config: {
        types: ['FACEBOOK_AD'],
        prompt: 'How to Write Clean, Modular Code\n',
        perspective: 'ACME',
        adGoal: 'AWARENESS',
        tov: 'personalTov',
      },
    },
  ],
};

const slice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setContent(state, action) {
      state.isLoading = false;
      state.content = [...action.payload, ...state.content.filter((item: any) => item.locked)];
    },
    removeContent(state, action) {
      state.content = state.content.filter((item: any) => item.id !== action.payload);
    },
    updateSingleContent(state, action) {
      state.isLoading = false;
      state.content = state.content.map((item: any) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
            // headline: action.payload.headline,
            // description: action.payload.description,
          };
        }
        return item;
      });
    },
    toggleLockContent(state, action) {
      state.isLoading = false;
      state.content = state.content.map((item: any) => {
        if (item.id === action.payload) {
          return { ...item, locked: !item.locked };
        }
        return item;
      });
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function setContent(content: any) {
  // return async () => {
  //   // dispatch(slice.actions.startLoading());
  //   try {
  dispatch(slice.actions.setContent(content));
  // } catch (error) {
  // dispatch(slice.actions.hasError(error));
  // }
  // };
}
export function removeContent(id: string) {
  // return async () => {
  //   // dispatch(slice.actions.startLoading());
  //   try {
  dispatch(slice.actions.removeContent(id));
  // } catch (error) {
  // dispatch(slice.actions.hasError(error));
  // }
  // };
}

export function toggleLockContent(id: string) {
  // return async () => {
  //   // dispatch(slice.actions.startLoading());
  //   try {
  dispatch(slice.actions.toggleLockContent(id));
  // } catch (error) {
  // dispatch(slice.actions.hasError(error));
  // }
  // };
}
export function updateSingleContent(id: string, headline: string, description: string) {
  console.log({ id, headline, description });

  // return async () => {
  //   // dispatch(slice.actions.startLoading());
  //   try {
  dispatch(slice.actions.updateSingleContent({ id, headline, description }));
  // } catch (error) {
  // dispatch(slice.actions.hasError(error));
  // }
  // };
}

// ----------------------------------------------------------------------
