import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import TextEditor from './TextEditor';

export default function JustWrite() {
  return (
    <Grid container spacing={6}>
      <Grid item xs={24} md={24}>
        <Box sx={{ mt: 8 }} />
        <TextEditor id="just_write" label={''} icon={''} description={''} config={{}} rows={8} />
      </Grid>
    </Grid>
  );
}
