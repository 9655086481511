import React, { useState, useEffect, useRef, useReducer } from 'react';
import {
  Stack,
  TextField,
  IconButton,
  Tooltip,
  Box,
  CircularProgress,
  ButtonGroup,
  Button,
  Typography,
  ClickAwayListener,
} from '@mui/material';
import Iconify from '../../components/Iconify';
import getCaretCoordinates from 'textarea-caret';
import { useSnackbar } from 'notistack';
import { post } from './api';
import { ContentActionTypes, AdsContentType, ContentTypes, AdTypes } from './types';
import { LoadingButton } from '@mui/lab';
import { useSelector } from '../../redux/store';
import { doc, setDoc } from 'firebase/firestore';
import { DB } from '../../contexts/FirebaseContext';
import { removeContent } from 'src/redux/slices/content';

interface State {
  currentIndex: number;
  generations: Entry[];
}
interface Entry {
  headline?: string;
  description: string;
}

interface Action {
  type: Actions;
  payload: Entry & { index?: number };
}
interface HistoryAction {
  type: Actions;
}

enum Actions {
  ADD = 'add',
  GO_BACK = 'goBack',
  GO_FORWARD = 'goForward',
}

function reducer(state: State, action: Action | HistoryAction) {
  switch (action.type) {
    case Actions.ADD:
      /**
       * If in hisory overwriting local changes
       */
      const isCurrentIndexBeforeLast = state.currentIndex < state.generations.length - 1;
      let newGenerations = [];
      if (isCurrentIndexBeforeLast) {
        newGenerations = [
          ...state.generations.filter((_, index) => index <= state.currentIndex),
          (action as Action).payload,
        ];
        /**
         * if at the end append history
         */
      } else {
        console.log(state.generations, (action as Action).payload);
        newGenerations = [...state.generations, (action as Action).payload];
      }
      console.log({ isCurrentIndexBeforeLast, newGenerations });

      return {
        ...state,
        generations: newGenerations,
        currentIndex: isCurrentIndexBeforeLast ? state.currentIndex : newGenerations.length - 1,
      };
    case Actions.GO_BACK:
      const hasEarlierHistory = state.currentIndex <= state.generations.length - 1;
      return {
        ...state,
        currentIndex: hasEarlierHistory ? state.currentIndex - 1 : state.currentIndex,
      };
    case Actions.GO_FORWARD:
      const hasLaterHistory = state.currentIndex <= state.generations.length - 1;
      return {
        ...state,
        currentIndex: hasLaterHistory ? state.currentIndex + 1 : state.currentIndex,
      };
    default:
      throw new Error();
  }
}

export default function TextEditorContainer({
  id,
  label,
  icon,
  type,
  headline,
  description,
  config,
  rows,
}: {
  id: string;
  label: string;
  icon: string;
  type?: ContentTypes | AdTypes;
  headline?: string;
  description: string;
  config: any;
  rows?: number;
}) {
  const [state, dispatch] = useReducer(reducer, {
    currentIndex: 0,
    generations: [{ headline, description }],
  });

  const hasUndoHistory = state.currentIndex > 0;
  const hasRedoHistory = state.currentIndex < state.generations.length - 1;
  return (
    <TextEditor
      id={id}
      label={label}
      icon={icon}
      type={type}
      headline={headline}
      description={description}
      state={state}
      currentIndex={state.currentIndex}
      config={config}
      rows={rows}
      undo={() => dispatch({ type: Actions.GO_BACK })}
      redo={() => dispatch({ type: Actions.GO_FORWARD })}
      appendHistory={(entry: Entry) => dispatch({ type: Actions.ADD, payload: entry })}
      hasUndoHistory={hasUndoHistory}
      hasRedoHistory={hasRedoHistory}
    />
  );
}

export function TextEditor({
  id,
  label,
  icon,
  type,
  headline,
  description,
  state,
  currentIndex,
  config,
  rows,
  hasUndoHistory,
  hasRedoHistory,
  appendHistory,
  undo,
  redo,
}: {
  id: string;
  label: string;
  icon: string;
  type?: ContentTypes | AdTypes;
  headline?: string;
  description: string;
  config: any;
  rows?: number;
  appendHistory: (entry: Entry) => void;
  hasUndoHistory: boolean;
  hasRedoHistory: boolean;
  undo: VoidFunction;
  redo: VoidFunction;
  state: State;
  currentIndex: number;
}) {
  console.log({ config });
  const ref = useRef<HTMLTextAreaElement>(null);

  const [editorHeadline, setEditorHeadline] = useState(headline);
  const [editorText, setEditorText] = useState(description);
  const [selectedText, setSelectedText] = useState('');
  const [loadingRephrase, setLoadingRephrase] = useState(false);
  const [loadingFullRetry, setLoadingFullRetry] = useState(false);
  const [loadingHeadlineRetry, setLoadingHeadlineRetry] = useState(false);
  const [loadingExtend, setLoadingExtend] = useState(false);
  const [loadingShorten, setLoadingShorten] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [pos, setPos] = useState([0, 0]);
  const { enqueueSnackbar } = useSnackbar();
  const content = useSelector((state) => state.content.content.find((c: any) => c.id === id));
  const userId = useSelector((state) => state.user.user?.id);
  const [coordinates, setCoordinates] = useState({ xStart: 0, xEnd: 0, y: 0, height: 0 });

  useEffect(() => {
    console.log({ currentIndex });
    // if (currentIndex) {
    setEditorText(state.generations[currentIndex].description);
    // }

    return () => {};
  }, [currentIndex]);

  const onCopy = () => {
    enqueueSnackbar('Copied!');
  };

  const onRephrase = async (full?: boolean) => {
    try {
      if (selectedText.length < 2 && !full) return null;
      setLoadingRephrase(true);
      const response = await post(
        'https://europe-west1-the-professor-app.cloudfunctions.net/prompt',
        {
          types: [ContentActionTypes.REPHRASE],
          prompt: full ? editorText : selectedText,
          context: config.prompt,
          tov: config?.tov,
        }
      );
      if (response[0].status === 429) {
        return enqueueSnackbar('Too many requests, please try again later');
      }
      if (response[0].status >= 400) {
        return enqueueSnackbar('Something went wrong, please try again later');
      }

      console.log({ response });

      if (full) {
        const newText = response[0]?.[ContentActionTypes.REPHRASE]?.description
          ?.split('\n')
          ?.join('');
        if (!newText) return enqueueSnackbar('Something went wrong, please try again later');
        appendHistory({ description: newText });
        setEditorText(newText);
        enqueueSnackbar('Improved your text');
      } else {
        const newSentence = response[0]?.[ContentActionTypes.REPHRASE]?.description
          ?.split('\n')
          ?.join('');
        // @ts-ignore
        console.log({ response, newSentence, data: response[0][ContentActionTypes.REPHRASE] });
        const newText = editorText.replace(selectedText, newSentence);
        if (!newText) return enqueueSnackbar('Something went wrong, please try again later');
        appendHistory({ description: newText });
        setEditorText(newText);
        setSelectedText('');
        enqueueSnackbar('Sentence rephrased');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRephrase(false);
    }
  };
  const onShorten = async (full?: boolean) => {
    try {
      if (selectedText.length < 2 && !full) return null;
      setLoadingShorten(true);
      const response = await post(
        'https://europe-west1-the-professor-app.cloudfunctions.net/prompt',
        {
          types: [ContentActionTypes.SHORTEN],
          prompt: full ? editorText : selectedText,
          context: config.prompt,
          tov: config?.tov,
        }
      );
      if (response[0].status === 429) {
        return enqueueSnackbar('Too many requests, please try again later');
      }
      if (response[0].status >= 400) {
        return enqueueSnackbar('Something went wrong, please try again later');
      }

      if (full) {
        const newText = response[0]?.[ContentActionTypes.SHORTEN]?.description
          ?.split('\n')
          ?.join('');
        if (!newText) return enqueueSnackbar('Something went wrong, please try again later');
        setEditorText(newText);
        appendHistory({ description: newText });
        enqueueSnackbar('Shortend your text');
      } else {
        const newSentence = response[0]?.[ContentActionTypes.SHORTEN]?.description
          ?.split('\n')
          ?.join('');
        const newText = editorText.replace(selectedText, newSentence);
        if (!newText) return enqueueSnackbar('Something went wrong, please try again later');
        appendHistory({ description: newText });
        setEditorText(newText);
        setSelectedText('');
        enqueueSnackbar('Sentence shortened');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingShorten(false);
    }
  };
  const onExtend = async (full?: boolean) => {
    try {
      if (selectedText.length < 2 && !full) return null;
      setLoadingExtend(true);
      const response = await post(
        'https://europe-west1-the-professor-app.cloudfunctions.net/prompt',
        {
          types: [ContentActionTypes.EXTEND],
          prompt: full ? editorText : selectedText,
          context: config.prompt,
          tov: config?.tov,
        }
      );
      if (response[0].status === 429) {
        return enqueueSnackbar('Too many requests, please try again later');
      }
      if (response[0].status >= 400) {
        return enqueueSnackbar('Something went wrong, please try again later');
      }

      if (full) {
        const newText = response[0]?.[ContentActionTypes.EXTEND]?.description
          ?.split('\n')
          ?.join('');
        if (!newText) return enqueueSnackbar('Something went wrong, please try again later');
        setEditorText(newText);
        appendHistory({ description: newText });
        enqueueSnackbar('Extended your text');
      } else {
        const newSentence = response[0]?.[ContentActionTypes.EXTEND]?.description
          ?.split('\n')
          ?.join('');

        const newText = editorText.replace(selectedText, newSentence);
        if (!newText) return enqueueSnackbar('Something went wrong, please try again later');
        appendHistory({ description: newText });
        setEditorText(newText);
        setSelectedText('');
        enqueueSnackbar('Sentence extended');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingExtend(false);
    }
  };
  const onFullRetry = async () => {
    try {
      setLoadingFullRetry(true);
      const response = await post(
        'https://europe-west1-the-professor-app.cloudfunctions.net/prompt',
        {
          types: [type],
          prompt: config.prompt,
          tov: config?.tov,
          perspective: config?.perspective,
          onlyBody: true,
        }
      );

      if (response[0].status === 429) {
        return enqueueSnackbar('Too many requests, please try again later');
      }
      if (response[0].status >= 400) {
        return enqueueSnackbar('Something went wrong, please try again later');
      }

      const newText = response[0]?.[type as string]?.description;
      if (!newText) return enqueueSnackbar('Something went wrong, please try again later');
      console.log({ response, newText, data: response[0][type as string] });
      appendHistory({ description: newText });
      setEditorText(newText);

      setSelectedText('');
      enqueueSnackbar('Full text regenerated');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFullRetry(false);
    }
  };
  const onRetryHeadline = async () => {
    try {
      setLoadingHeadlineRetry(true);
      const response = await post(
        'https://europe-west1-the-professor-app.cloudfunctions.net/prompt',
        {
          types: [type],
          prompt: config.prompt,
          tov: config?.tov,
          perspective: config?.perspective,
          onlyHeadline: true,
        }
      );

      if (response[0].status === 429) {
        return enqueueSnackbar('Too many requests, please try again later');
      }
      if (response[0].status === 500) {
        return enqueueSnackbar('Something went wrong, please try again later');
      }

      const newHeadline = response[0]?.[type as string]?.headline;

      console.log({ response, newHeadline, data: response[0][type as string] });
      if (!newHeadline) return enqueueSnackbar('Something went wrong, please try again later');
      setEditorHeadline(newHeadline);

      setSelectedText('');
      enqueueSnackbar('headline text regenerated');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHeadlineRetry(false);
    }
  };

  useEffect(() => {
    // @ts-ignore
    document.getElementById(id).addEventListener('select', function () {
      // @ts-ignore
      var caret = getCaretCoordinates(this, this.selectionEnd);
      // @ts-ignore
      var caret2 = getCaretCoordinates(this, this.selectionStart);
      console.log('(top, left, height) = (%s, %s, %s)', caret.top, caret.left, caret.height);
      console.log('(top2, left2, height2) = (%s, %s, %s)', caret2.top, caret2.left, caret2.height);
      setCoordinates({ xStart: caret2.left, xEnd: caret.left, y: caret.top, height: caret.height });
    });

    return () => {};
  }, []);

  // const handleInput = (event: any) => {
  //   const { value, selectionStart, selectionEnd } = event.target;
  //   if (ref?.current) {
  //     // @ts-ignore
  //     const { left, top, height } = ref.current.getBoundingClientRect();
  //     const selectedText = value.substring(selectionStart, selectionEnd);
  //     const selectedTextIndex = value.indexOf(selectedText);
  //     const selectionHeight = getTextHeight(selectedText, ref.current);
  //     setPos([left, top + height + selectionHeight]);
  //   }
  // };

  // const getTextHeight = (text: string, textArea: any) => {
  //   const textAreaClone = textArea.cloneNode(true);
  //   textAreaClone.value = text;
  //   document.body.appendChild(textAreaClone);
  //   const { height } = textAreaClone.getBoundingClientRect();
  //   textAreaClone.remove();
  //   return height;
  // };

  const onSelectingText = (e: any) => {
    // @ts-ignore
    const y = ref.current?.offsetTop;
    // @ts-ignore
    const x = ref.current?.offsetWidth / 2;

    console.log({ x, y, text: e.target.value });
    // console.log({ e });
    // if (!ref?.current) return;
    // const selection = window.getSelection();
    // console.log({ selection });

    // if (!selection?.rangeCount) return;
    // const range = selection?.getRangeAt(0);
    // console.log({ range });

    // const rect = range?.getBoundingClientRect();
    // console.log({ rect });

    // const x = rect.left + window.pageXOffset;
    // const y = rect.top + window.pageYOffset;
    // console.log(x, y);

    // if (e.nativeEvent?.clientX) {
    //   const x = e.nativeEvent?.clientX;
    //   const y = e.nativeEvent?.clientY;

    //   setPos([x, y]);
    // }

    // if (e.nativeEvent?.target) {
    //   console.log({
    //     c: e.target,
    //   });
    // }

    // const localX = e.clientX - e.target.offsetLeft;
    // const localY = e.clientY - e.target.offsetTop;
    // // @ts-ignore
    // // @ts-ignore
    // // @ts-ignore
    // const y = ref.current?.offsetTop;
    // // @ts-ignore
    // const x = ref.current?.offsetWidth;
    // // @ts-ignore
    // const selectionStart = ref?.current?.selectionStart;
    // // @ts-ignore
    // const selectionEnd = ref?.current?.selectionEnd;
    // console.log({ localX, localY, x, y, selectionStart, selectionEnd });
    // setPos([x, y]);
    const text = window?.getSelection()?.toString().trim();
    setSelectedText(text || '');
  };

  const handleSave = async () => {
    try {
      console.log({ content });

      setIsSaving(true);
      if (!userId) throw new Error('User not found');
      await setDoc(doc(DB, `users/${userId}/content`, id), {
        ...content,
        createTime: new Date(),
        headline: editorHeadline || '',
        description: editorText,
      });
      enqueueSnackbar('Content was saved, you can find it in your library');
      removeContent(id);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong, please try again later');
    } finally {
      setIsSaving(false);
    }
  };

  const disableActions =
    !editorText || loadingRephrase || loadingShorten || loadingExtend || loadingFullRetry;

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Stack alignContent="center">
          <Iconify icon={icon} />
        </Stack>
        <Typography>{label}</Typography>
      </Stack>
      <Box sx={{ py: 0.5 }} />
      {/* Text to replace: {selectedText} */}
      <Stack direction="row" spacing={2}>
        <ClickAwayListener onClickAway={() => setSelectedText('')}>
          <div style={{ position: 'relative', width: '100%' }}>
            {[AdsContentType.FACEBOOK_AD, AdsContentType.GOOGLE_AD].includes(type as any) && (
              <>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TextField
                    id={id}
                    size="small"
                    inputRef={ref}
                    disabled={loadingHeadlineRetry}
                    value={editorHeadline}
                    fullWidth
                    rows={rows}
                    // onMouseUp={onSelectingText}
                    onChange={(e) => setEditorHeadline(e.target.value)}
                  />
                  <Box>
                    <Tooltip title="Regenerate title">
                      <IconButton>
                        {loadingHeadlineRetry ? (
                          <CircularProgress color="primary" size={12} />
                        ) : (
                          <Iconify
                            icon={'ic:baseline-autorenew'}
                            width={20}
                            height={20}
                            onClick={onRetryHeadline}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
                <Box sx={{ mt: 2 }} />
              </>
            )}
            <TextField
              id={id}
              inputRef={ref}
              disabled={loadingRephrase}
              value={editorText}
              fullWidth
              multiline
              rows={rows}
              onMouseUp={onSelectingText}
              onChange={(e) => setEditorText(e.target.value)}
              onBlur={(e) => {
                console.log('onBlur', editorText, e.target.value);
                if (state.generations[currentIndex].description !== e.target.value) {
                  console.log('onBlur update');
                  appendHistory({ description: e.target.value });
                }
              }}
            />
            <div style={{ position: 'absolute', marginTop: 16 }}>
              {/* <ButtonGroup variant="contained" aria-label="outlined primary button group"> */}
              <Tooltip title={'Magic improve text'}>
                <IconButton
                  // sx={{ opacity: selectedText ? 1 : 0.5 }}
                  onClick={() => onRephrase(true)}
                  disabled={disableActions}
                >
                  {loadingRephrase ? (
                    <CircularProgress color="primary" size={12} />
                  ) : (
                    <Iconify icon={'mdi:wand'} width={24} height={24} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={'Make text shorter'}>
                <IconButton
                  // sx={{ opacity: selectedText ? 1 : 0.5 }}
                  onClick={() => onShorten(true)}
                  disabled={disableActions}
                >
                  {loadingShorten ? (
                    <CircularProgress color="primary" size={12} />
                  ) : (
                    <Iconify icon={'mdi:collapse-vertical'} width={24} height={24} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={'Make text longer'}>
                <IconButton
                  // sx={{ opacity: selectedText ? 1 : 0.5 }}
                  onClick={() => onExtend(true)}
                  disabled={disableActions}
                >
                  {loadingExtend ? (
                    <CircularProgress color="primary" size={12} />
                  ) : (
                    <Iconify icon={'mdi:expand-vertical'} width={24} height={24} />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            {/* <Button>Three</Button> */}
            {/* </ButtonGroup> */}
            {selectedText && (
              <>
                <div
                  style={{
                    position: 'absolute',
                    zIndex: 100,
                    left: coordinates.xEnd,
                    top: coordinates.y - coordinates.height,
                  }}
                >
                  <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Tooltip title="Rephrase sentence">
                      <Button onClick={() => onRephrase()} disabled={disableActions}>
                        {loadingRephrase ? (
                          <CircularProgress color="primary" size={12} />
                        ) : (
                          <Iconify icon={'mdi:wand'} width={24} height={24} />
                        )}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Make text shorter">
                      <Button onClick={() => onShorten()} disabled={disableActions}>
                        {loadingShorten ? (
                          <CircularProgress color="primary" size={12} />
                        ) : (
                          <Iconify icon={'mdi:collapse-vertical'} width={24} height={24} />
                        )}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Make text longer">
                      <Button onClick={() => onExtend()} disabled={disableActions}>
                        {loadingExtend ? (
                          <CircularProgress color="primary" size={12} />
                        ) : (
                          <Iconify icon={'mdi:expand-vertical'} width={24} height={24} />
                        )}
                      </Button>
                    </Tooltip>
                    {/* <Button>Three</Button> */}
                  </ButtonGroup>
                </div>
                {/* <div
                  style={{
                    position: 'absolute',
                    zIndex: -1,
                    backgroundColor: 'red',
                    left: coordinates.xStart + 14,
                    width: coordinates.xEnd - coordinates.xStart,
                    height: coordinates.height,
                    top: coordinates.y + coordinates.height - 8,
                  }}
                /> */}
              </>
            )}
          </div>
        </ClickAwayListener>
      </Stack>
      <Box sx={{ py: 1 }} />
      <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
        <Stack>
          <Tooltip title={'Undo change'}>
            <IconButton // sx={{ opacity: selectedText ? 1 : 0.5 }}
              onClick={undo}
              disabled={!hasUndoHistory}
            >
              <Iconify icon={'lucide:undo-2'} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack>
          <Tooltip title={'Redo change'}>
            <IconButton
              // sx={{ opacity: selectedText ? 1 : 0.5 }}
              onClick={redo}
              disabled={!hasRedoHistory}
            >
              <Iconify icon={'lucide:redo-2'} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack>
          <Tooltip title="Retry full generation">
            <IconButton onClick={onFullRetry} disabled={disableActions}>
              {loadingFullRetry ? (
                <CircularProgress color="primary" size={12} />
              ) : (
                <Iconify icon={'ic:baseline-autorenew'} width={24} height={24} />
              )}
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack>
          {/* <CopyToClipboard text={editorText} onCopy={onCopy}>
            <Tooltip title="Copy">
              <IconButton>
                <Iconify icon={'eva:copy-fill'} width={24} height={24} />
              </IconButton>
            </Tooltip>
          </CopyToClipboard> */}
        </Stack>
        <LoadingButton
          variant="contained"
          size="large"
          loading={isSaving}
          disabled={!editorText}
          onClick={handleSave}
        >
          Save
        </LoadingButton>
      </Stack>
    </>
  );
}
