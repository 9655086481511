import { createSlice } from '@reduxjs/toolkit';

//
import { dispatch, RootState } from '../store';
import { PerspectiveOptions } from '../../pages/creator/types';
import { CreditDefault, Subscription } from '../../enums';

// ----------------------------------------------------------------------

export interface UserDoc {
  id: string;
  companyIndustry?: string;
  companyName?: string;
  companyDescription?: string;
  firstName?: string;
  lastName?: string;
  personalIndustry?: string;
  bioShort?: string;
  bioLong?: string;
  displayName?: string;
  firstname?: string;
  lastname?: string;
  credits: number;
  writingFor?: PerspectiveOptions;
  personalTov?: string;
  personalText?: string;
  stripeRole: Subscription;
  trialStart: Date;
  subscription: Subscription | null;
}

export interface UserState {
  isLoading: boolean;
  error: null | any;
  user: UserDoc | null;
}

const initialState: UserState = {
  isLoading: true,
  error: null,
  user: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUserAuth(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
    setUser(state, action) {
      state.isLoading = false;
      state.user = { ...state.user, ...action.payload };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

export const selectCreditTotal = (state: RootState) => {
  if (state.user.user?.stripeRole === Subscription.UNLIMITED) {
    return 'unlimited';
  }
  // if (state.user.user?.stripeRole === Subscription.STARTER) {
  //   return CreditDefault.BASE_CREDITS;
  // }
  return CreditDefault.BASE_CREDITS / 3;
};

// ----------------------------------------------------------------------

export function setUser(user: any) {
  console.log({ setUser: user });

  // dispatch(slice.actions.startLoading());

  dispatch(slice.actions.setUser(user));
}
export function setUserAuth(user: any) {
  console.log({ setUserAuth: user });

  // dispatch(slice.actions.startLoading());

  dispatch(slice.actions.setUserAuth(user));
}

// ----------------------------------------------------------------------
