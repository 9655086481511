import React from 'react';
import { RHFSelect } from '../../components/hook-form';

export enum emailTypes {
  SALES_OUTREACH = 'sales outreach',
  MARKETING = 'marketing',
  PRODUCT_LAUNCH = 'product launch',
  COLD = 'cold',
  SUMMARY = 'summary',
}
const TONE_OF_VOICE = [
  { key: emailTypes.SALES_OUTREACH, label: 'Sales outreach' },
  { key: emailTypes.MARKETING, label: 'Marketing' },
  { key: emailTypes.PRODUCT_LAUNCH, label: 'Product launch' },
  { key: emailTypes.COLD, label: 'Cold' },
  { key: emailTypes.SUMMARY, label: 'Summary' },
];
export default function EmailTypeSelector() {
  return (
    <RHFSelect name="emailType" label="Type of email">
      {TONE_OF_VOICE.map(({ key, label }) => (
        <option key={key} value={key}>
          {label}
        </option>
      ))}
    </RHFSelect>
  );
}
