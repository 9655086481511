import { AUTH } from '../../contexts/FirebaseContext';

export async function post(endpoint: string, body: any): Promise<any[]> {
  try {
    const token = (await AUTH.currentUser?.getIdToken()) ?? null;
    const response = await fetch(endpoint, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    console.log({ response });

    if (response.status >= 400) {
      console.log(response.status);
      return [response];
    }
    const data = await response.json();
    console.log({ data });
    return data as any[];
  } catch (error) {
    console.error(error);
    return [error];
  }
}
