import { isMobile } from 'react-device-detect';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

const DeviceDetector = () => {
  const theme = useTheme();

  if (!isMobile) {
    return null;
  }
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      style={{
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        left: 0,
        top: 0,
        backgroundColor: theme.palette.brand.dark,
        zIndex: 9999,
      }}
    >
      <Typography>
        This experience is not optimised for Mobile, please use your laptop.🙏
      </Typography>
    </Stack>
  );
};

export default DeviceDetector;
