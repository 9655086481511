import React, { useEffect, useState } from 'react';
import { Stack, IconButton, Box, Typography, Skeleton } from '@mui/material';
import Iconify from '../../components/Iconify';
import useSuggestions from '../../hooks/useSuggestions';
import { httpsCallable } from 'firebase/functions';
import { Functions } from 'src/contexts/FirebaseContext';
import { useSnackbar } from 'notistack';

// const suggestions = ['The difference between removal and avoidance', 'Carbon removal explained'];

export default function Suggestions({
  handleAddSuggetion,
}: {
  handleAddSuggetion: (s: string) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loadNewSuggestions, setloadNewSuggestions] = useState(false);
  const { isLoading, suggestions } = useSuggestions();
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    handleShuffle();
    return () => {};
  }, [suggestions]);

  const handleShuffle = () => {
    const shuffled = shuffle(suggestions);
    const [one, two, three] = shuffled;
    setOptions([one, two, three]);
  };
  const getNewSuggestions = async () => {
    // await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      setloadNewSuggestions(true);

      const functionRef = httpsCallable(Functions, 'generateSuggestions');
      const response: any = await functionRef();
      // const configId = response?.data?.id;

      // @ts-ignore
      const error = response?.data?.httpErrorCode?.status;
      if (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
        return;
      }
      enqueueSnackbar('New suggestions generated', { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    } finally {
      setloadNewSuggestions(false);
    }
  };

  const isEmpty = !suggestions || suggestions?.length < 1;
  console.log({ isEmpty, suggestions });

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="subtitle1">Suggestions</Typography>
        <IconButton onClick={getNewSuggestions} disabled={isEmpty || loadNewSuggestions}>
          <Iconify icon={'ic:baseline-autorenew'} width={20} height={20} />
        </IconButton>
      </Stack>
      <Box sx={{ py: 0.5 }} />
      {isEmpty && !isLoading && (
        <Typography variant="body2" color="text.secondary">
          Complete your profile to get suggestions
        </Typography>
      )}
      {isLoading || loadNewSuggestions ? (
        <>
          <Skeleton height={40} sx={{ backgroundColor: 'grey.200' }} />
          <Skeleton height={40} sx={{ backgroundColor: 'grey.200' }} />
          <Skeleton height={40} sx={{ backgroundColor: 'grey.200' }} />
        </>
      ) : (
        <Stack spacing={2}>
          {options.map((s, i) => (
            <Typography
              key={i}
              variant="body2"
              onClick={() => handleAddSuggetion(s)}
              sx={{ cursor: 'pointer' }}
            >
              {s}
            </Typography>
          ))}
        </Stack>
      )}
    </>
  );
}

function shuffle(array: string[]) {
  if (!array || array?.length < 1) return [];
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}
