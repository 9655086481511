import React from 'react';
import { Alert, styled } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../routes/paths';

export default function Upgrade() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const show = useSelector(
  //   (state) => !state.user?.user?.subscription || state.user?.user?.stripeRole === 'free'
  // );
  // if (!show) return null;
  return (
    <CustomAlert
      icon={false}
      color="success"
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        navigate(PATH_DASHBOARD.general.creator);
        if (pathname === PATH_DASHBOARD.general.creator) {
          navigate(0);
        }
      }}
    >
      Start
    </CustomAlert>
  );
}

const CustomAlert = styled(Alert)(({ theme }) => ({
  borderRadius: 0,
  backgroundColor: `${theme.palette.primary.main} !important`,
  color: theme.palette.common.white,
}));
