import React from 'react';
import { RHFSelect } from '../../components/hook-form';

export enum promptTypes {
  SUMMARY = 'summary',
  BULLETS = 'bullet point list',
}
const TONE_OF_VOICE = [
  { key: promptTypes.BULLETS, label: 'Bullet point list' },
  { key: promptTypes.SUMMARY, label: 'Summary' },
];
export default function PromptEmailSelector() {
  return (
    <RHFSelect name="promptType" label="Type of format">
      {TONE_OF_VOICE.map(({ key, label }) => (
        <option key={key} value={key}>
          {label}
        </option>
      ))}
    </RHFSelect>
  );
}
