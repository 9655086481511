import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import useUser from '../hooks/useUser';
import { useSelector } from 'src/redux/store';
import { Subscription } from 'src/enums';
import { getDaysLeftInTrial } from 'src/utils/formatTime';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const hasUser = useSelector((state) => state.user.user?.id);
  const shouldPay = useSelector(
    (state) =>
      (!state.user?.user?.subscription ||
        state.user?.user?.subscription !== Subscription.UNLIMITED) &&
      getDaysLeftInTrial(state.user?.user?.trialStart) <= 0
  );
  console.log({ shouldPay });

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  useUser();
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  const payPath = `${PATH_DASHBOARD.user.account}?tab=billing`;
  if (hasUser && shouldPay && !pathname.includes(PATH_DASHBOARD.user.account)) {
    return <Navigate to={payPath} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
