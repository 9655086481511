import React, { useEffect, useState } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
// import { useDispatch } from '../redux/store';
import useAuth from './useAuth';
import { DB } from '../contexts/FirebaseContext';
import { setUser } from '../redux/slices/user';

export default function useUser() {
  // const dispatch = useDispatch();
  const [isLoadingUser, setisLoadingUser] = useState(true);
  // const userId = useSelector((state) => state.user.uid);
  const { user } = useAuth();
  const userId = user?.id;
  useEffect(() => {
    let unsubscribe: () => void;
    if (userId) {
      const q = doc(DB, 'users', userId);
      unsubscribe = onSnapshot(q, (doc) => {
        const userData = doc.data();

        async function setUserData() {
          // const stripeRole = await getStripeRole();
          console.log({ ...user, ...userData });
          // @ts-ignore
          setUser({ ...user, ...userData, trialStart: userData?.trialStart?.toDate() });
          setisLoadingUser(false);
        }
        setUserData();
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
  return { isLoadingUser };
}
