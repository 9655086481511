// @mui
import { styled } from '@mui/material/styles';
import { Grid, Stack, Typography, Box, Chip, Tooltip } from '@mui/material';
import { Categories } from './Page';

export default function SelectCategoryStep({
  handleClick,
}: {
  handleClick: (category: Categories) => void;
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box sx={{ p: 4, pt: 6 }}>
          <Stack>
            <Typography variant="h3">What do you want to do?</Typography>
          </Stack>
          <Box sx={{ py: 2 }} />
          <Stack direction="row" spacing={2}>
            <CustomChip
              variant="outlined"
              label="Social media post"
              onClick={() => handleClick(Categories.SOME)}
            />
            <Box>
              <CustomChip
                label="Ads"
                variant="outlined"
                onClick={() => handleClick(Categories.ADS)}
              />
            </Box>
          </Stack>
          <Box sx={{ py: 1 }} />
          <Stack direction="row" spacing={2}>
            <Box>
              <CustomChip
                label="Email"
                variant="outlined"
                onClick={() => handleClick(Categories.EMAIL)}
              />
            </Box>
            <Tooltip title="This feature is not ready yet">
              <Box>
                <CustomChip
                  label="Just write"
                  variant="outlined"
                  onClick={() => {
                    alert('coming soon');
                  }} // handleClick(Categories.JUST_WRITE)}
                />
              </Box>
            </Tooltip>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export const CustomChip = styled(Chip)(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 16,
  paddingRight: 16,
  fontSize: 18,
  borderRadius: 28,
  height: 'inherit',
  '&:hover': {
    // @ts-ignore
    backgroundColor: `${theme.palette.yellow.main} !important`,
  },
}));
